export const theme = {
  field : {
    gap              : '20px 0 0',
    placeholderColor : '#7e84a3',
  },
  color : {
    black          : '#131523',
    blue           : '#0058ff',
    darkGray       : '#7e84a3',
    gray           : '#a1a7c4',
    green          : '#21d59b',
    lightGray      : '#e6e9f4',
    lightOrange    : '#f99600',
    mobileText     : '#454545',
    orange         : '#f0552a',
    red            : '#f0142f',
    ultraLightBlue : '#0058ff1a',
  },
  font : {
    basicSans      : 'Basic Sans Regular',
    basicSansBold  : 'Basic Sans Bold',
    basicSansLight : 'Basic Sans Light',
    benton         : 'Benton Sans Comp Bd',
    futuraDemi     : 'Futura PT Demi',
    futuraMedium   : 'Futura PT Medium',
    henriette      : 'Henriette Bold',
    roboto         : 'Roboto Condensed Regular',
  },
};
