import { Field, Form }    from 'formik';
import { useTranslation } from 'react-i18next';
import styled             from 'styled-components';

import { LoadingOutlined } from '@ant-design/icons';

import { Button, Checkbox, Input } from '../../../components';
import { NewsieLogo }              from '../../../../public/svg';

interface LoginFormProps {
  isFetching             : boolean;
  passwordRecovery?      : boolean;
  onClickForgotPassword? : () => void;
  onClickContactNewsie?  : () => void;
}

export const LoginForm = ({
  isFetching,
  passwordRecovery,
  onClickContactNewsie,
  onClickForgotPassword,
}: LoginFormProps) => {
  const { t } = useTranslation();

  return (
    <LoginForm.Wrapper opacity={isFetching ? 0.5 : 1}>
      {isFetching && (
        <LoginForm.LoaderWrapper>
          <LoadingOutlined />
        </LoginForm.LoaderWrapper>
      )}
      <NewsieLogo width="403px" />
      <h3>{passwordRecovery ? t('passwordRecovery') : t('login')}</h3>
      <Form>
        <Field
          component    = {Input}
          disabled     = {!!passwordRecovery}
          label        = {t('email')}
          name         = "email"
          placeholder  = {t('enterYourEmail')}
          type         = "email"
          wrapperStyle = {() => 'margin: 50px 0 0'}
        />
        <Field
          component   = {Input}
          label       = {t('password')}
          name        = "password"
          placeholder = {t('enterYourPassword')}
          type        = "password"
        />
        {passwordRecovery && (
          <Field
            component   = {Input}
            label       = {t('confirmPassword')}
            name        = "passwordCopy"
            placeholder = {t('enterYourPasswordAgain')}
            type        = "password"
          />
        )}
        <LoginForm.Buttons passwordRecovery={passwordRecovery}>
          {!passwordRecovery && (
            <Field
              component = {Checkbox}
              label     = {t('rememberMe')}
              name      = "rememberActive"
            />
          )}
          <Button label={passwordRecovery ? t('submit') : t('login')} />
        </LoginForm.Buttons>
      </Form>
      {!passwordRecovery && (
        <LoginForm.Footer>
          <Button bordered={false} label={t('forgotPassword')} onClick={onClickForgotPassword} />
          <Button bordered={false} label={t('contactNewsie')} onClick={onClickContactNewsie} />
        </LoginForm.Footer>
      )}
    </LoginForm.Wrapper>
  );
};

LoginForm.Buttons = styled.div<{ passwordRecovery?: boolean; }>`
  align-items     : center;
  display         : flex;
  justify-content : ${({ passwordRecovery }) => (passwordRecovery ? 'flex-end' : 'space-between')};
  margin-top      : 52px;
`;

LoginForm.Footer = styled.div`
  display         : flex;
  justify-content : center;
  height          : 20px;
  margin-top      : 112px;

  > button {
    background-color : transparent;
    border           : 'none';
    border-radius    : 0;
    height           : 20px;
    padding          : 0 7px;

    &:last-child { border-left: 1px solid #5a607f; }
  }
`;

LoginForm.LoaderWrapper = styled.div`
  align-items     : center;
  display         : flex;
  font-size       : 40px;
  height          : 100%;
  justify-content : center;
  position        : absolute;

  > span { color: ${({ theme }) => theme.color.orange}; }
`;

LoginForm.Wrapper = styled.div<{ opacity: number }>`
  align-items      : center;
  background-color : #f4f7fc;
  display          : flex;
  flex-direction   : column;
  justify-content  : center;
  min-width        : 500px;

  > h3 {
    color       : ${({ theme }) => theme.color.black};
    font-size   : 35px;
    line-height : 49px;
    margin-top  : 91px;
  }

  > img,
  > form,
  > h3,
  > div:not(:first-child) {
    width: 403px;
    transition: all 0.3s;
    opacity: ${({ opacity }) => opacity};
  }

  input {
    background-color: #f4f7fc;

    &:hover,
    &:focus { background-color: #f4f7fc; }
  }
`;
