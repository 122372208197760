import { format, getYear, setYear }         from 'date-fns';
import { zonedTimeToUtc }                   from 'date-fns-tz';
import { Form, Field, Formik, FormikProps } from 'formik';
import moment                               from 'moment';
import styled                               from 'styled-components';
import * as Yup                             from 'yup';
import {
  ChangeEvent,
  forwardRef,
  useCallback,
  useState,
  useEffect,
  useMemo,
  useRef,
} from 'react';

import { ArrowLeftOutlined } from '@ant-design/icons';

import {
  Button,
  DatePicker,
  Label,
  Input,
} from '@common/components';

import { Notifications }      from '@components/Notifications/Notifications';
import { FormList, IElement } from '@components/List/FormList';
import { Title }              from '@components/Title/Title';

import { callApi }            from '@utils/apiCaller';

const { FIREBASE_WEB_API_KEY }  = process.env;
const { PROJECT_URL }           = process.env;
const { ANDROID_PACKAGE_NAME }  = process.env;
const { IOS_BUNDLE_ID }         = process.env;
const { IOS_APP_STORE_ID }      = process.env;
const { APPCENTER_ANDROID_URL } = process.env;
const { APPCENTER_IOS_URL }     = process.env;

interface IPromoCode {
  code                : string;
  name                : string;
  businessLocationId  : number;
  startDateUtc        : string;
  endDateUtc          : string;
  firebaseDynamicLink : string;
}

interface IPromoCodeFormProps extends FormikProps<IInitPromoCode> {
  editMode : boolean;
  loading  : boolean;
  onDelete : () => void;
}

interface IInitPromoCode {
  code                : string | null;
  name                : string | null;
  startDateUtc        : string | null;
  endDateUtc          : string | null;
  firebaseDynamicLink : string | null;
}

interface IAddPromoCode {
  promoCode : IPromoCode | null;
  onSubmit  : (values: any) => void;
  onDelete  : (pc: IPromoCode) => void;
  isLoading : boolean;
}

const Container = styled.div<{ ref: any }>`
  min-width        : 500px;
  background-color : #fff;
`;

const BackIconWrapper = styled.div`
  position : absolute;
  top      : 10px;
  left     : 10px;
`;

const LabelBusinessWrapper = styled.div`
  margin-top      : 16px;
  display         : flex;
  flex-direction  : row;
  justify-content : space-between;
`;

const NoPromoCodes = styled.p`
  color      : ${({ theme }) => theme.textColor.gray};
  text-align : center;
  margin-top : 30px;
`;

const ButtonWrapper = styled.div`
  display         : flex;
  justify-content : end;
  margin-top      : 20px;

  > button:last-child {
    margin-left: 15px;
  }
`;

const LabelLinkWrapper = styled.div`
  margin-top      : 16px;
  display         : flex;
  flex-direction  : row;
  align-items     : center;
`;

const Link = styled.p`
  align-self : flex-end;
  font-size  : 18px;
`;

const createFirebaseDynamicLink = async (code: string) => {
  const requestData = {
    subdomain           : 'https://newsie.page.link',
    link                : `${PROJECT_URL}/promocode/${code}`,
    androidFallbackLink : APPCENTER_ANDROID_URL,
    iosFallbackLink     : APPCENTER_IOS_URL,
    androidPackageName  : ANDROID_PACKAGE_NAME,
    iosStoreId          : IOS_APP_STORE_ID,
    iosBundleId         : IOS_BUNDLE_ID,
    efr	                : 1,
  };

  let longDynamicLink = `${requestData.subdomain}/?link=${requestData.link}&apn=${requestData.androidPackageName}&isi=${requestData.iosStoreId}&ibi=${requestData.iosBundleId}&efr=${requestData.efr}`;

  if (requestData.androidFallbackLink && requestData.iosFallbackLink) {
    longDynamicLink += `&afl=${requestData.androidFallbackLink}&ifl=${requestData.iosFallbackLink}`;
  }

  const response = await fetch(
    `https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${FIREBASE_WEB_API_KEY}`,
    {
      method  : 'POST',
      headers : {
        'Content-Type': 'application/json',
      },
      body    : JSON.stringify({
        longDynamicLink,
        suffix          : {
          option: 'SHORT',
        },
      }),
    },
  );

  const responseData = await response.json();

  return responseData;
};

const useValidationSchema = (isPromoCodeExist: boolean) => {
  const validationSchema = useMemo(() => Yup.object().shape({
    code         : isPromoCodeExist
      ? Yup.string()
      : Yup.string()
        .test(
          'is-uniq',
          'This promo code already exists and should be unique',
          async (value) => {
            try {
              await callApi(`feature/PromoCode/search?promoCode=${value}`, 'get');

              return false;
            } catch (e) {
              return true;
            }
          },
        )
        .matches(/^[A-Z\s]/, 'Enter a promo code which consists only of capital letters')
        .typeError('Enter a promo code which consists only of letters'),
    endDateUtc   : Yup.string().nullable(),
    name         : Yup.string().typeError('Enter promo code name').required(),
    startDateUtc : Yup.string().typeError('Select the date from which the promo code is valid').required(),
  }), [isPromoCodeExist]);

  return validationSchema;
};

const PromoCodeForm = ({
  editMode,
  loading,
  onDelete,
  setFieldValue,
  values,
}: IPromoCodeFormProps) => {
  const prevStartDate = useRef(values.startDateUtc);

  const [deleteConfirmed, setDeleteConfirmStatus] = useState(false);

  const onClickDelete = useCallback(() => {
    if (deleteConfirmed) {
      onDelete();
    } else {
      setDeleteConfirmStatus(true);
    }
  }, [deleteConfirmed]);

  const onPromoCodeChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const nextValue = e.target.value && e.target.value.replace(/[^a-zA-Z-]+/g, '').slice(0, 16).toUpperCase();

    setFieldValue('code', nextValue);
  }, [setFieldValue]);

  useEffect(() => {
    if (!prevStartDate.current && editMode) {
      prevStartDate.current = values.startDateUtc;
    } else if (values.startDateUtc && values.startDateUtc !== prevStartDate.current) {
      prevStartDate.current = values.startDateUtc;

      const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const endDateUTC    = zonedTimeToUtc(new Date(values.startDateUtc), localTimeZone);

      setFieldValue('endDateUtc', setYear(endDateUTC, getYear(endDateUTC) + 1));
    }
  }, [values.startDateUtc]);

  return (
    <Form>
      <Field
        label       = "name"
        name        = "name"
        placeholder = "Enter a name"
        component   = {Input}
      />
      <Field
        disabled            = {editMode}
        label               = "code"
        name                = "code"
        placeholder         = "Enter a code"
        component           = {Input}
        useInternalOnChange = {false}
        onChange            = {onPromoCodeChange}
      />
      <Field
        label     = "starts"
        name      = "startDateUtc"
        component = {DatePicker}
      />
      <Field
        component       = {DatePicker}
        datePlaceholder = {format(setYear(new Date(), getYear(new Date()) + 1), 'MMM dd, yyyy')}
        label           = "ends"
        name            = "endDateUtc"
      />
      {editMode && (
        <>
          <LabelLinkWrapper>
            <Label label="Invite Link" />
            <Link style={{ fontSize: 15, marginLeft: 15 }}>{values.firebaseDynamicLink}</Link>
          </LabelLinkWrapper>
        </>
      )}
      <ButtonWrapper>
        {editMode && (
          <Button
            disabled = {loading}
            label    = {deleteConfirmed ? 'Confirm' : 'Delete'}
            onClick  = {onClickDelete}
            type     = "button"
          />
        )}
        <Button
          disabled = {loading}
          label    = {editMode ? 'Save' : 'Add'}
        />
      </ButtonWrapper>
    </Form>
  );
};

const AddPromoCode = ({ onSubmit, promoCode, onDelete, isLoading }: IAddPromoCode) => {
  const validationSchema = useValidationSchema(!!promoCode);

  const initialValues = useMemo<IInitPromoCode>(() => ({
    code                : promoCode?.code || null,
    name                : promoCode?.name || null,
    startDateUtc        : promoCode?.startDateUtc || null,
    endDateUtc          : promoCode?.endDateUtc || null,
    firebaseDynamicLink : promoCode?.firebaseDynamicLink || null,
  }), [promoCode]);

  const onClickDelete = useCallback(() => {
    if (promoCode) {
      onDelete(promoCode);
    }
  }, [promoCode, onDelete]);

  return (
    <Formik
      enableReinitialize
      validateOnBlur
      initialValues    = {initialValues}
      onSubmit         = {onSubmit}
      validationSchema = {validationSchema}
    >
      {({ ...props }: FormikProps<IInitPromoCode>) => (
        <PromoCodeForm
          {...props}
          editMode = {!!promoCode}
          loading  = {isLoading}
          onDelete = {onClickDelete}
        />
      )}
    </Formik>
  );
};

export const PromoCodes = forwardRef(({
  initialValues = {},
}: any, ref) => {
  const promoCode = useRef<IPromoCode | null>(null);
  const [promoCodes, setPromoCodes]               = useState<IElement[]>([]);
  const [isAddOrEditMode, setAddOrEditModeStatus] = useState(false);
  const [isLoading, setLoadingStatus]             = useState(false);

  const backHandler = useCallback(() => {
    promoCode.current = null;

    setAddOrEditModeStatus(false);
  }, [setAddOrEditModeStatus, promoCode]);

  const fetchPromoCodes = useCallback(async () => {
    const { data } = await callApi(`feature/promocode/bybusinesslocation/${initialValues.businessLocationId}`, 'get');

    const listData: IElement[] = data.map((pc: IPromoCode) => ({
      primary   : pc.name,
      secondary : `${pc.code}, ${moment(pc.startDateUtc).format('MM/DD/YYYY')} - ${pc.endDateUtc ? moment(pc.endDateUtc).format('MM/DD/YYYY') : '...'}`,
      key       : pc.code,
      source    : pc,
    }));

    setPromoCodes(listData);
  }, [setPromoCodes]);

  const onEditPromoCode = useCallback((el: IElement) => {
    promoCode.current = el.source as IPromoCode;

    setAddOrEditModeStatus(true);
  }, [promoCode, setAddOrEditModeStatus]);

  const onDeletePromoCode = useCallback(async (pc: IPromoCode) => {
    try {
      setLoadingStatus(true);
      await callApi(`feature/promocode/?code=${pc.code}`, 'delete');
      setLoadingStatus(false);
      fetchPromoCodes();
      backHandler();
      Notifications.enqueueSnackbar('Promocode was deleted successfully', 'success');
    } catch (e) {
      Notifications.enqueueSnackbar('Ooops... Something went wrong while deleting PromoCode. Please try again', 'error');
    }
  }, [setLoadingStatus, backHandler]);

  const onSubmit = useCallback(async (values: any) => {
    try {
      const requestData = {
        ...values,
        businessLocationId: initialValues.businessLocationId,
      };

      setLoadingStatus(true);
      if (!requestData.firebaseDynamicLink) {
        const { shortLink } = await createFirebaseDynamicLink(values.code);

        requestData.firebaseDynamicLink = shortLink;
      }

      const { data } = await callApi('feature/promocode', promoCode.current ? 'put' : 'post', requestData);
      setLoadingStatus(false);
      Notifications.enqueueSnackbar(`PromoCode was ${promoCode.current ? 'edited': 'added'} successfully`, 'success');
      // eslint-disable-next-line require-atomic-updates
      promoCode.current = data;

      fetchPromoCodes();
    } catch (e) {
      setLoadingStatus(false);
      Notifications.enqueueSnackbar(`Ooops... Something went wrong while ${promoCode.current ? 'editing': 'adding'} PromoCode. Please try again`, 'error');
    }
  }, [fetchPromoCodes, initialValues, backHandler, promoCode, setLoadingStatus]);

  useEffect(() => {
    fetchPromoCodes();
  }, []);

  return (
    <Container ref={ref} tabIndex={1}>
      {isAddOrEditMode && (
        <BackIconWrapper>
          <ArrowLeftOutlined onClick={backHandler} style={{ fontSize: '16px'}} />
        </BackIconWrapper>
      )}
      <Title name={isAddOrEditMode ? 'Promo Code' : 'Manage Promo Codes'} />
      {isAddOrEditMode
        ? (
          <AddPromoCode
            onSubmit  = {onSubmit}
            promoCode = {promoCode.current}
            isLoading = {isLoading}
            onDelete  = {onDeletePromoCode}
          />
        )
        : (
          <>
            <LabelBusinessWrapper>
              <Label label="promo codes" />
              <Button
                label   = "Add Promo Code"
                type    = "button"
                onClick = {() => setAddOrEditModeStatus(true)}
              />
            </LabelBusinessWrapper>
            <FormList
              data   = {promoCodes}
              onEdit = {onEditPromoCode}
            />
            {promoCodes.length === 0 && (
              <NoPromoCodes>
                No promo codes yet
              </NoPromoCodes>
            )}
          </>
        )}
    </Container>
  );
});
