import { useCallback, useRef } from 'react';
import styled                  from 'styled-components';

import { Button, Modal } from '@common/components';

import { BaseImageResizer, IBaseImageResizer } from '@common/components/ImageResizer';

interface IResizersListProps extends Omit<IBaseImageResizer, 'image' | 'onSubmit' | 'file' | 'getResizeFile'> {
  images   : File[];
  onClose  : () => void;
  onSubmit : (images: File[]) => void;
}

export const ResizersList = ({ images, onClose, onSubmit, ...props }: IResizersListProps) => {
  const resizeFunctionsRef = useRef<{ [index: number]: (() => Promise<File>) }>({});

  const onClickSave = useCallback(async () => {
    const promises = Object
      .values(resizeFunctionsRef.current)
      .reduce((result: Promise<File>[], ref: () => Promise<File>) => {
        if (ref) {
          result.push(ref());
        }

        return result;
      }, []);

    const resizedFiles = await Promise.all(promises);

    onSubmit(resizedFiles);
  }, [resizeFunctionsRef, onSubmit]);

  const getResizeFile = useCallback((resizeFile: () => Promise<File>, index) => {
    resizeFunctionsRef.current[index] = resizeFile;
  }, []);

  return (
    <Modal
      onCancel  = {onClose}
      title     = "Resizer"
      width     = "auto"
      bodyStyle = {() => `
        max-height : 600px;
        overflow   : auto;
      `}
    >
      <ResizersList.Wrapper>
        {images.map((image, index) => (
          <BaseImageResizer
            {...props}
            aspect        = {1/1}
            file          = {image}
            getResizeFile = {(file) => getResizeFile(file, index)}
            key           = {image.name}
          />
        ))}
        <Button
          label   = "Submit"
          onClick = {onClickSave}
          type    = "button"
        />
      </ResizersList.Wrapper>
    </Modal>
  );
};

ResizersList.Wrapper = styled.div`
  align-items    : center;
  display        : flex;
  flex-direction : column;
  gap            : 10px;
  max-width      : 600px;

  > button,
  div { width: fit-content; }
`;
