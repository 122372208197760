import { FieldProps }  from 'formik';
import { useCallback } from 'react';
import styled          from 'styled-components';

import { BaseInput, BaseInputProps } from './BaseInput';
import { FieldError }                from '../FieldError';

interface PhoneInputProps extends BaseInputProps, FieldProps {
  wrapperStyle? : { margin?: string };
}

export const PhoneInput = ({
  field,
  form,
  wrapperStyle,
  ...componentProps
}: PhoneInputProps) => {
  const formatPhoneNumber = useCallback((phoneNumber: string) => {
    const match = phoneNumber.match(/^(\d{3})(\d{3})(\d{4})$/);

    if (match) {
      return `(${match[1]}) ${match[2]}-${match[3]}`;
    }

    return null;
  }, [field]);

  const onChange = useCallback((nextValue: string) => {
    if (formatPhoneNumber(nextValue)) {
      form.setFieldValue(field.name, formatPhoneNumber(nextValue));
    } else {
      form.setFieldValue(field.name, nextValue.replace(/\D+/g, ''));
    }
  }, [field, form, formatPhoneNumber]);

  return (
    <PhoneInput.Wrapper {...wrapperStyle}>
      <BaseInput
        {...componentProps}
        onChange = {onChange}
        type     = "tel"
        value    = {field.value}
      />
      <FieldError
        field = {field}
        form  = {form}
      />
    </PhoneInput.Wrapper>
  );
};

PhoneInput.Wrapper = styled.div<{ margin?: string; }>`
  margin   : ${({ margin, theme }) => margin || theme.field.gap};
  position : relative;
`;
