import { Form, Field, FormikProps }       from 'formik';
import { useCallback, useEffect, useRef } from 'react';
import { useTranslation }                 from 'react-i18next';
import styled                             from 'styled-components';

import {
  Checkbox,
  DatePicker,
  Input,
  Button,
  PlacesAutocomplete,
  PhoneInput,
} from '@common/components';

import { EntityStatusModal, IEntityStatusFormValues } from '@components/EntityStatus/EntityStatus';
import { MultipleAutoSuggest }                        from '@components/Autosuggest/MultipleAutoSuggest';
import { MediaUploader }                              from '@components/MediaUploader/MediaUploader';
import { MultiMediaUploader }                         from '@components/MultiMediaUploader/MultiMediaUploader';
import { AutoSuggest }                                from '@components/Autosuggest/Autosuggest';
import { Title }                                      from '@components/Title/Title';

import {
  IChannelModel,
  ITerritoryModel,
  ICategoryModel,
}                          from '@models/index';
import { TERRITORY_TYPES } from '@modules/territories/pages/AddTerritoryContainerL';
import { callApi }         from '@utils/apiCaller';

import { TBranchFormValues }  from '../pages/AddBranchContainer';

interface IBranchForm {
  businessLocationId : number | null;
  confirmDelete      : boolean;
  isLoading          : boolean;
  onDeleteInternal   : () => void;
}

export const BranchForm = ({
  setFieldValue,
  values,
  onDeleteInternal,
  isLoading,
  confirmDelete,
  businessLocationId,
}: FormikProps<TBranchFormValues> & IBranchForm) => {
  const { t } = useTranslation();

  const prevGooglePlaceId = useRef<string | null>(values.location?.googlePlaceId || null);

  const onChangeBranchStatus = useCallback(async (values: IEntityStatusFormValues) => {
    if (!businessLocationId) { return; }

    try {
      await callApi(`feature/BusinessLocation/${businessLocationId}/status`, 'patch', values);
      setFieldValue('status', values.status);
    } catch (error) {
      console.error(error);
    }
  }, [businessLocationId, setFieldValue, values]);

  const getReviewsNotification = useCallback((isYelpReviews: boolean) => {
    if (values.isWithoutAddress || (!values.location?.address1 && isYelpReviews)) {
      return `${isYelpReviews ? 'Yelp' : 'Google'} reviews are not available without address.`;
    } else if (!values.location?.googlePlaceId) {
      return 'Find business via business lookup to connect google reviews.';
    }

    return null;
  }, [values]);

  const mapChannelsForChips = useCallback((data: IChannelModel[]) => {
    return data.map((item) => ({ label: item.name, value: item.channelId }));
  }, []);

  const mapSubcategoriesForChips = useCallback((data: ICategoryModel[]) => {
    return data.map((item) => ({ value: item.categoryId, label: item.name }));
  }, []);

  const mapTerritoriesForChips = useCallback((data: ITerritoryModel[]) => {
    return data.map((item) => ({ label: item.name, value: item.territoryId }));
  }, []);

  const mapSearchResponseYelpBusiness = useCallback((data: { businesses: { categories: { title: string }[]; id: string; name: string }[] }) => {
    return data.businesses.map((business) => ({
      value : business.id,
      label : `${business.name}${business.categories
        ? `(${business.categories.map((category) => category.title).join(', ')})`
        : ''}`,
    }));
  }, []);

  const rBusinessLocationCredentialRef = useRef(values.rBusinessLocationCredential);
  useEffect(() => {
    rBusinessLocationCredentialRef.current = values.rBusinessLocationCredential;
  }, [values.rBusinessLocationCredential]);

  const prepopulateContactsIfBusinessPlaceIdProvided = useCallback(async (placeId: string | null) => {
    if (!placeId) return;
    // eslint-disable-next-line no-undef
    const service = new google.maps.places.PlacesService(document.createElement('div'));
    service.getDetails({
      placeId,
      fields: ['name', 'formatted_phone_number', 'website', 'opening_hours', 'types'],
    }, (response) => {
      if (response && response.types && response.types.includes('establishment')) {
        setFieldValue('googleBusinessName', response.name, false);
      }

      setFieldValue('phoneNumber', response?.formatted_phone_number || null, false);
      setFieldValue('websiteUri', response?.website || null, false);
      const operationalHours = Object.prototype.toString.call(response?.opening_hours?.weekday_text) === '[object Array]'
        ? response?.opening_hours?.weekday_text?.join('\n')
        : null;

      setFieldValue('operationalHours', operationalHours || null, false);
    });
  }, [values.location, setFieldValue]);

  useEffect(() => {
    if (prevGooglePlaceId.current !== values.location?.googlePlaceId) {
      prevGooglePlaceId.current = values.location?.googlePlaceId || null;

      prepopulateContactsIfBusinessPlaceIdProvided(values.location?.googlePlaceId || null);
    }
  }, [prevGooglePlaceId, values.location?.googlePlaceId]);

  const prevYelpReviewStatus   = useRef(values.yelpReviewsActive);
  const prevGoogleReviewStatus = useRef(values.googleReviewsActive);
  useEffect(() => {
    if (values.isWithoutAddress) {
      setFieldValue('googleReviewsActive', false);
      setFieldValue('yelpReviewsActive', false);

      prevGoogleReviewStatus.current = values.googleReviewsActive;
      prevYelpReviewStatus.current   = values.yelpReviewsActive;
    } else {
      setFieldValue('googleReviewsActive', prevGoogleReviewStatus.current);
      setFieldValue('yelpReviewsActive', prevYelpReviewStatus.current);
    }
  }, [values.isWithoutAddress]);

  return (
    <>
      <div style={{marginTop: '20px'}}><Title name={`${businessLocationId ? 'Edit' : 'Add'} Branch`} /></div>
      <BranchForm.Wrapper>
        <BranchForm.Column>
          <Field
            label       = "name"
            name        = "name"
            placeholder = "Enter a name"
            component   = {Input}
          />
          <Field
            multiline
            maxCharacters = {600}
            label         = "description"
            name          = "description"
            placeholder   = "Enter a description"
            component     = {Input}
          />
          <Field
            aspectRatio     = {1}
            component       = {MediaUploader}
            label           = "icon"
            mimeTypeOptions = {['image']}
            name            = "iconUri"
            imageStyle      = {{
              height : '200px',
              margin : '14px 0 0',
            }}
          />
          <Field
            aspectRatio     = {405 / 221}
            component       = {MediaUploader}
            label           = "sme image"
            mimeTypeOptions = {['image']}
            name            = "smeImage"
            required        = {false}
            imageStyle      = {{
              height : '226px',
              margin : '14px 0 0',
            }}
          />
          <Field
            multiline
            required      = {false}
            label         = "sme description"
            maxCharacters = {600}
            name          = "smeDescription"
            placeholder   = "Enter a description"
            component     = {Input}
          />
          <Field
            required
            aspect    = {16 / 9}
            component = {MultiMediaUploader}
            label     = "Media"
            limit     = {6}
            name      = "rBusinessImage"
          />
          <Field
            component = {Checkbox}
            label     = {t('withoutAddress')}
            name      = "isWithoutAddress"
          />
          {!values.isWithoutAddress && (
            <>
              <Field
                autoComplete = "nope"
                required     = {false}
                types        = {['establishment']}
                label        = "google business"
                name         = "googleBusinessName"
                placeholder  = "Find your business in Google"
                component    = {PlacesAutocomplete}
              />
              <Field
                autoComplete = "nope"
                types        = {['address']}
                label        = "address 1"
                name         = "location.address1"
                placeholder  = "Street Address"
                component    = {PlacesAutocomplete}
              />
              <Field
                required    = {false}
                label       = "address 2"
                name        = "location.address2"
                placeholder = "Suite #"
                component   = {Input}
              />
              <Field
                required    = {false}
                label       = "district"
                name        = "location.district"
                placeholder = "District"
                component   = {Input}
              />
              <Field
                label       = "city"
                name        = "location.city"
                placeholder = "City"
                component   = {Input}
              />
              <Field
                label       = "state"
                name        = "location.state"
                placeholder = "State or Province"
                component   = {Input}
              />
              <Field
                label       = "zip"
                name        = "location.postCode"
                placeholder = "Zip or Postal Code"
                component   = {Input}
              />
              <Field
                label       = "country"
                name        = "location.country"
                placeholder = "Country"
                component   = {Input}
              />
            </>
          )}
        </BranchForm.Column>

        <BranchForm.Column rightSide>
          <Field
            label          = "add to areas"
            name           = "rBusinessTerritory"
            placeholder    = "Assign Area(s)"
            endpoint       = {`feature/territory/search?type=${TERRITORY_TYPES.CITY}&type=${TERRITORY_TYPES.CITY_COLLECTION}&type=${TERRITORY_TYPES.DIVIDED_CITY}&name`}
            responseMapper = {mapTerritoriesForChips}
            component      = {MultipleAutoSuggest}
          />
          <Field
            label          = "add to subcategories"
            name           = "rBusinessBusinessCategory"
            placeholder    = "Assign Subcategory(s)"
            endpoint       = "feature/category/search?fromSubcategory=true&name"
            responseMapper = {mapSubcategoriesForChips}
            component      = {MultipleAutoSuggest}
          />
          <Field
            acceptedFormats    = "image/png"
            aspectRatio        = {null}
            component          = {MediaUploader}
            deleteIconPosition = "center"
            label              = "logo"
            mimeTypeOptions    = {['image']}
            name               = "logoUri"
            required           = {false}
            imageStyle         = {{
              height : '47px',
              margin : '14px 0 0',
            }}
          />
          <BranchForm.Checkboxes>
            <Field
              component    = {Checkbox}
              disabled     = {getReviewsNotification(false)}
              label        = "Google reviews"
              name         = "googleReviewsActive"
              notification = {getReviewsNotification(false)}
            />
            <Field
              label     = {t('activateAlert')}
              name      = "isAlertActive"
              component = {Checkbox}
            />
            <Field
              component    = {Checkbox}
              disabled     = {getReviewsNotification(true)}
              label        = "Yelp reviews"
              name         = "yelpReviewsActive"
              notification = {getReviewsNotification(true)}
            />
          </BranchForm.Checkboxes>
          <Field
            required    = {false}
            label       = "phone number"
            name        = "phoneNumber"
            placeholder = "(012) 345-6789"
            format      = "(###) ###-#####"
            component   = {PhoneInput}
          />
          <Field
            required    = {false}
            label       = "web site"
            name        = "websiteUri"
            placeholder = "http://"
            component   = {Input}
          />
          {values.isAlertActive && (
            <>
              <Field
                label       = "alert header"
                name        = "alertHeader"
                placeholder = "Alert header"
                component   = {Input}
              />
              <Field
                multiline
                label         = "alert description"
                maxCharacters = {600}
                name          = "alertText"
                placeholder   = "Alert description"
                component     = {Input}
              />
            </>
          )}
          {values.yelpReviewsActive && (
            <Field
              label           = "Yelp username"
              name            = "yelpBusinessName"
              fieldName       = "yelpBusinessId"
              placeholder     = {values.location?.googlePlaceId ? 'Find your business in Yelp' : 'To link Yelp Business add Google location'}
              endpoint        = {`review/businesssearch?latitude=${values.location?.geog?.coordinate?.y}&longitude=${values.location?.geog?.coordinate?.x}&radius=5000&term`}
              responseMapper  = {mapSearchResponseYelpBusiness}
              component       = {AutoSuggest}
            />
          )}
          <Field
            onlyDate
            isFutureDisabled
            required       = {false}
            disableToolbar = {false}
            label          = "established year"
            name           = "establishedDate"
            views          = {['year']}
            component      = {DatePicker}
          />
          <Field
            multiline
            required      = {false}
            label         = "hours of operation"
            name          = "operationalHours"
            alignLabel    = "top"
            component     = {Input}
            maxCharacters = {600}
            placeholder   = {`Monday - Friday 8:00 am to 10:00 pm
              Saturday 10 am to midnight
              Sunday Closed
            `}
          />
          <Field
            required       = {false}
            label          = "add to channels"
            name           = "rBusinessLocationChannel"
            placeholder    = "Assign Channel(s)"
            endpoint       = "feature/channel/search?name"
            responseMapper = {mapChannelsForChips}
            component      = {MultipleAutoSuggest}
          />
          {businessLocationId && (
            <EntityStatusModal
              entityName = {values.name}
              onSubmit   = {onChangeBranchStatus}
              status     = {values.status}
            />
          )}
        </BranchForm.Column>

        <BranchForm.ButtonWrapper>
          <Button
            label    = {businessLocationId ? 'Save' : 'Add'}
            disabled = {isLoading}
          />
          {businessLocationId && (
            <Button
              type     = "button"
              onClick  = {onDeleteInternal}
              label    = {confirmDelete ? 'Confirm' : 'Delete'}
              disabled = {isLoading}
            />
          )}
        </BranchForm.ButtonWrapper>
      </BranchForm.Wrapper>
    </>
  );
};

BranchForm.ButtonWrapper = styled.div`
  display         : flex;
  justify-content : end;
  margin-bottom   : 20px;
  margin-left     : 200px;
  gap             : 20px;
  width           : 100%;

  div { width: 100%; }
`;

BranchForm.Checkboxes = styled.div`
  display         : flex;
  justify-content : space-between;

  > div { margin: 28px 0 9px; }
`;

BranchForm.Column = styled.div<{ rightSide?: boolean }>`
  width : 402.5px;

  ${({ rightSide }) => rightSide && `
    display         : flex;
    flex-direction  : column;
    justify-content : start;
  `}
`;

BranchForm.Wrapper = styled(Form)`
  display    : flex;
  flex-wrap  : wrap;
  gap        : 20px 80px;
`;
